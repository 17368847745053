import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAlzrlJg90l_muqu_dByxIyylj6RfIUP_c",
    authDomain: "costa-rica-tours1.firebaseapp.com",
    databaseURL: "https://costa-rica-tours1-default-rtdb.firebaseio.com",
    projectId: "costa-rica-tours1",
    storageBucket: "costa-rica-tours1.appspot.com",
    messagingSenderId: "801262009401",
    appId: "1:801262009401:web:a1adcdd3490bdbb27821e8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const db = getFirestore(app);