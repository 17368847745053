import React from 'react';
import './LocationTab.css'; // Import CSS file for styling

function LocationTab({ location }) {
  const embedUrl = location
    ? `https://www.google.com/maps/embed/v1/place?key=AIzaSyBMK_Fe4tTMA0_2_5sQ9BHs1OM6qIZIJ0s&q=${location.latitude},${location.longitude}`
    : null;

  return (
    <div className="tab-section">
      <h2>Location</h2>
      {embedUrl ? (
        <iframe
          title="Tour Location"
          src={embedUrl}
          frameBorder="0"
          allowFullScreen
          className="map-iframe"
        />
      ) : (
        <p>No location provided.</p>
      )}
    </div>
  );
}

export default LocationTab;
