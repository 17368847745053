import React, { useEffect } from 'react';
import './Alert.css'; // Import CSS for styling

const Alert = ({ message, onClose, type }) => {
  useEffect(() => {
    if (message) {
      // Automatically close the alert after 3 seconds
      const timer = setTimeout(() => {
        console.log("Alert triggered: ", message, " type: ", type)
        onClose();
      }, 3000); // 3000 milliseconds = 3 seconds

      // Clear the timer if the component unmounts or message changes
      return () => clearTimeout(timer);
    }
  }, [message, onClose]);

  if (!message) return null;

  const alertClass = `alert ${type}`;

  return (
    <div className="alert-container">
      <div className={alertClass}>
        <i className={`fas ${type === 'error' ? 'fa-exclamation-triangle' : type === 'success' ? 'fa-check-circle' : 'fa-info-circle'}`}></i>
        <span>{message}</span>
      </div>
    </div>
  );
};

export default Alert;
