import React from 'react';
import './DetailsTab.css'; // Import CSS for styling

function DetailsTab({ tour }) {
  if (!tour) return <p>Loading...</p>;

  const {
    description,
    price,
    aditional_info: additionalInfo
  } = tour;

  console.log(tour)

  return (
    <div className="details-tab-container">
      <div className="price-info">
        <strong>Price per person:</strong> ${price}
      </div>
      <p>{description}</p>
      <table className="additional-info-table">
        <thead>
          <tr>
            <th>Feature</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {additionalInfo && Object.entries(additionalInfo).map(([key, value]) => (
            <tr key={key}>
              <td>{key.replace(/-/g, ' ').toUpperCase()}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default DetailsTab;
