import React from 'react';
import './MediaTab.css'; // Import CSS for styling

function MediaTab({ media }) {
  if (!media) return <p>No media available.</p>;

  const { images = [], videos = [] } = media;

  // Combine videos and images into a single array for display, videos first
  const allMedia = [...videos, ...images];

  return (
    <div className="media-tab-container">
      <h2>Photos and Videos</h2>
      <div className="media-grid-container">
        {allMedia.map((item, index) => {
          // Check if the media item is a video URL
          const isVideo = item.includes('youtube.com') || item.includes('youtu.be');
          
          return (
            <div className="media-item-container" key={index}>
              {isVideo ? (
                <iframe
                  title={`Video ${index}`}
                  src={`https://www.youtube.com/embed/${item.split('v=')[1]}`}
                  allowFullScreen
                  className="media-item"
                />
              ) : (
                <img
                  src={item}
                  alt={`Media ${index}`}
                  className="media-item"
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MediaTab;
