import { collection, getDocs, doc, getDoc, query, where } from "firebase/firestore";
import { db } from "./firebase";

export async function getAllTours(filters = {}) {
  const toursCollection = collection(db, "tour");

  let q = toursCollection;

  // Apply filters if provided
  if (filters) {
    const conditions = [];
    for (const [field, value] of Object.entries(filters)) {
      if (Array.isArray(value)) {
        // Example: { price: ["<", 100] }
        conditions.push(where(field, value[0], value[1]));
      } else {
        // Example: { location: "Costa Rica" }
        conditions.push(where(field, "==", value));
      }
    }
    q = query(toursCollection, ...conditions);
  }

  try {
    const querySnapshot = await getDocs(q);
    const tours = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    return tours;
  } catch (error) {
    console.error("Error getting tours:", error);
    return [];
  }
}

export async function getTourById(tourId) {
  const tourDoc = doc(db, "tour", tourId);
  try {
    const docSnapshot = await getDoc(tourDoc);
    if (docSnapshot.exists()) {
      return { id: docSnapshot.id, ...docSnapshot.data() };
    } else {
      console.error("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error getting tour:", error);
    return null;
  }
}
