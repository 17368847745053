import React, { useState, useEffect } from 'react';
import './ContactInfo.css'; // Import CSS for styling
import Alert from '../../Alert/Alert';

function ContactInfo({ nextStep, updateBookInfo, bookInfo }) {
  const [countries, setCountries] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    // Fetch country codes from JSON file
    fetch('/countries.json')
      .then(response => response.json())
      .then(data => setCountries(data))
      .catch(error => console.error('Error fetching countries:', error));
  }, []);

  const validateStep = () => {
    const { email, firstName, lastName, phoneNumber, countryCode } = bookInfo;

    // Regular expression for validating email format
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Initialize the error message
    let error = '';

    if (!email) {
      error = 'Email is required';
    } else if (!emailRegex.test(email)) {
      error = 'Email is not valid';
    } else if (!firstName) {
      error = 'First name is required';
    } else if (!lastName) {
      error = 'Last name is required';
    } else if (!phoneNumber) {
      error = 'Phone number is required';
    } else if (!countryCode) {
      error = 'Country code is required';
    }

    // Set the alert message to the first error if any
    if (error) {
      setAlertMessage(error);
    } else {
      nextStep(); // Proceed to the next step if form is valid
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateBookInfo(prev => ({ ...prev, [name]: value })); // Pass updated data to parent
  };

  const handleAlertClose = () => {
    setAlertMessage(''); // Hide the alert when the close button is clicked
  };

  return (
    <div>
      <h2>Step 1: Contact Information</h2>
      <div className="form-group">
        <label className="form-label">Email:</label>
        <input
          type="email"
          name="email"
          value={bookInfo.email}
          placeholder="Enter your email"
          onChange={handleChange}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label className="form-label">First Name:</label>
        <input
          type="text"
          name="firstName"
          value={bookInfo.firstName}
          placeholder="Enter your first name"
          onChange={handleChange}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label className="form-label">Last Name:</label>
        <input
          type="text"
          name="lastName"
          value={bookInfo.lastName}
          placeholder="Enter your last name"
          onChange={handleChange}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label className="form-label">Country Code:</label>
        <select name="countryCode" value={bookInfo.countryCode} onChange={handleChange} className="form-control">
          <option value="">Select Country Code</option>
          {countries.map((country, index) => (
            <option key={index} value={country.code}>
              {country.code} ({country.name})
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label className="form-label">Phone Number:</label>
        <input
          type="text"
          name="phoneNumber"
          value={bookInfo.phoneNumber}
          placeholder="Enter your phone number"
          onChange={handleChange}
          className="form-control"
        />
      </div>
      <button onClick={validateStep} className="next-button">
        Next
      </button>
      <Alert message={alertMessage} onClose={handleAlertClose} type="error" />
    </div>
  );
}

export default ContactInfo;
