import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAllTours } from '../../firebase/tour';
import './TourList.css';  // Import CSS file for styling

function TourList() {
  const [tours, setTours] = useState([]);

  useEffect(() => {
    const filters = { enable: true };
    const fetchTours = async () => {
      const data = await getAllTours(filters);
      console.log(data)
      setTours(data);
    };
    fetchTours();
  }, []);

  return (
    <div>
      <h1 className='tour-list-title'>Tour List</h1>
      <div className="tour-list-container">
        <div className="tour-list">
          {tours.map(tour => (
            <div className="tour-card" key={tour.id}>
              <div className="tour-image-container">
                <img src={`${tour.image}`} alt={tour.name} className="tour-image" />
                <div className="tour-price">${tour.price}</div>
              </div>
              <div className="tour-details">
                <h2>{tour.name}</h2>
                <p>{tour.description}</p>
                <p><strong>Duration:</strong> {tour.duration}</p>
                <Link to={`/tour/${tour.id}`} className="tour-link">More Details</Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TourList;
