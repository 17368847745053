import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import LocationTab from './LocationTab/LocationTab';
import DetailsTab from './DetailsTab/DetailsTab';
import MediaTab from './MediaTab/MediaTab';
import './TourDetail.css'; // Import CSS for styling
import { getTourById } from '../../firebase/tour';
import { getBookCount } from '../../firebase/book';
import { addEmptyBook } from '../../firebase/book';

function TourDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [tour, setTour] = useState(null);
  const [bookCount, setBookCount] = useState(null);
  const [activeTab, setActiveTab] = useState('details');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTour = async () => {
      const data = await getTourById(id);
      setTour(data);
    };
    const fetchBookCount = async () => {
      const count = await getBookCount();
      console.log("book count: ", count)
      setBookCount(count + 1);
      setLoading(false);
    };

    fetchTour();
    fetchBookCount();
  }, [id]);

  const handleBookNowClick = async () => {
    if (bookCount !== null && tour) {
      const bookId = bookCount + 1;
      const returnedBookId = await addEmptyBook(bookId); // Add the book to Firestore
      navigate(`/book/${returnedBookId}/tour/${id}`);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!tour) {
    return <p>Tour not found</p>;
  }

  return (
    <div className="tour-detail-container">
      <h1>{tour.name}</h1>
      <div className="tab-card-container">
        <button className="book-now-button" onClick={handleBookNowClick}>
          <i className="fas fa-calendar-check"></i> Book Now
        </button>
        <div className="tabs">
          <div
            className={`tab ${activeTab === 'location' ? 'active' : ''}`}
            onClick={() => setActiveTab('location')}
          >
            Location
          </div>
          <div
            className={`tab ${activeTab === 'details' ? 'active' : ''}`}
            onClick={() => setActiveTab('details')}
          >
            Details
          </div>
          <div
            className={`tab ${activeTab === 'media' ? 'active' : ''}`}
            onClick={() => setActiveTab('media')}
          >
            Photos/Videos
          </div>
        </div>
        <div className="tab-content">
          {activeTab === 'location' && <LocationTab location={tour.location} />}
          {activeTab === 'details' && <DetailsTab tour={tour} />}
          {activeTab === 'media' && <MediaTab media={tour.media} />}
        </div>
      </div>
    </div>
  );
}

export default TourDetail;
