import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ContactInfo from './ContactInfo/ContactInfo';
import DateSelection from './DateSelection/DateSelection';
import PersonSelection from './PersonSelection/PersonSelection';
import Payment from './Payment/Payment';
import Receipt from './Receipt/Receipt'; 
import './Book.css'; // Import CSS for styling
import { getTourById } from '../../firebase/tour';
import { updateBook } from '../../firebase/book';

function Book() {
  const { bookId, tourId } = useParams(); // Get the ID from URL parameters
  const [currentStep, setCurrentStep] = useState(1);
  const [bookInfo, setBookInfo] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    countryCode: '',
    date: '',
    numPersons: 1,
    totalPrice: 0
  });
  const [tour, setTour] = useState(null); // State to hold tour data

  useEffect(() => {
    const fetchTour = async () => {
      const data = await getTourById(tourId);
      setTour(data);
    };
    fetchTour();
  }, [bookId, tourId]);

  const nextStep = async () => {
    if (bookId) {
      try {
        await updateBook(bookId, {
          ...bookInfo,
          tourId
        });
      } catch (error) {
        console.error("Error updating book:", error);
      }
    }
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  }

  if (!tour) return <p>Loading tour details...</p>; // Show loading state while fetching

  return (
    <div className="book-container">
      {/* Progress Bar */}
      <div className="progress-bar">
        <div
          className="progress-bar-fill"
          style={{ width: `${(currentStep / 4) * 100}%` }}
        />
      </div>

      {currentStep === 1 && (
        <ContactInfo
          nextStep={nextStep}
          updateBookInfo={setBookInfo}
          bookInfo={bookInfo} // Pass current bookInfo
        />
      )}
      {currentStep === 2 && (
        <DateSelection
          nextStep={nextStep}
          prevStep={prevStep}
          bookInfo={bookInfo}
          updateBookInfo={setBookInfo}
        />
      )}
      {currentStep === 3 && (
        <PersonSelection
          nextStep={nextStep}
          prevStep={prevStep}
          bookInfo={bookInfo}
          updateBookInfo={setBookInfo}
          pricePerPerson={tour.price} // Use the tour price
        />
      )}
      {currentStep === 4 && (
        <Payment
          prevStep={prevStep}
          tour={tour}
          bookInfo={bookInfo}
        />
      )}
    </div>
  );
}

export default Book;
