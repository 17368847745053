import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DateSelection.css';
import CustomDatePicker from '../../utilities/DatePicker/CustomDatePicker';

function DateSelection({ nextStep, prevStep, bookInfo, updateBookInfo }) {
  const [selectedDate, setSelectedDate] = useState(bookInfo.date ? new Date(bookInfo.date) : null);
  const [isValid, setIsValid] = useState(!!selectedDate);

  const handleChange = (date) => {
    setSelectedDate(date);
    setIsValid(date !== null);

    // Update bookInfo with the new date
    updateBookInfo(prev => ({
      ...prev,
      date: date ? date.toISOString().split('T')[0] : ''
    }));
  };

  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    <div className="date-selection-container">
      <h2>Step 2: Select Date</h2>
      <div className="form-group">
        <DatePicker
          selected={selectedDate}
          onChange={handleChange}
          minDate={tomorrow}
          customInput={<CustomDatePicker />}
        />
      </div>
      <button onClick={prevStep} className="prev-button">
        Previous
      </button>
      <button onClick={nextStep} disabled={!isValid} className="next-button">
        Next
      </button>
    </div>
  );
}

export default DateSelection;
