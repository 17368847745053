import React from 'react';
import './Footer.css'; // Add a CSS file if needed for styling

function Footer() {
  return (
    <footer className="footer-container">
      <p>&copy; 2024 Rio Celeste Tours</p>
      <p>Contact us: <a href="mailto:info@riocelestetours.net">info@riocelestetours.net</a> | +506 84106388</p>
      <div className="footer-social">
        <a href="https://www.facebook.com/RioCelesteTours" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a>
        <a href="https://www.instagram.com/RioCelesteTours" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
        <a href="https://www.twitter.com/RioCelesteTours" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
      </div>
    </footer>
  );
}

export default Footer;
