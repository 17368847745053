import React, { forwardRef } from 'react';
import './CustomDatePicker.css';
// Custom input component that triggers the calendar on click
const CustomDatePicker = forwardRef(({ value, onClick }, ref) => (
<button className="custom-date-input" onClick={onClick} ref={ref}>
    {value || 'Select a date'}
</button>
));


export default CustomDatePicker;
