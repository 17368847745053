import React, { useState, useEffect } from 'react';
import './PersonSelection.css'; // Import CSS for styling

function Persons({ nextStep, prevStep, updateBookInfo, bookInfo, pricePerPerson }) {
  const [totalPrice, setTotalPrice] = useState(pricePerPerson);

  const handleChange = (e) => {
    const value = e.target.value;
    bookInfo.numPersons = value
    bookInfo.totalPrice = Number((value * pricePerPerson).toFixed(2))
    setTotalPrice(bookInfo.totalPrice)
    updateBookInfo(bookInfo);
  };

  useEffect(() => {
    bookInfo.totalPrice = pricePerPerson
  }, [bookInfo, pricePerPerson]);

  return (
    <div className="persons-container">
      <h2>Step 3: Select Number of Persons</h2>
      <div className="form-group">
        <input
          type="number"
          value={bookInfo.numPersons}
          min="1"
          onChange={handleChange}
          className="form-control"
        />
      </div>
      <p className="total-price">Total Price: ${totalPrice}</p>
      <button onClick={prevStep} className="prev-button">
        Previous
      </button>
      <button onClick={nextStep} className="next-button">
        Next
      </button>
    </div>
  );
}

export default Persons;
