import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';  // Import CSS file for styling
import logo from '../../assets/tours-logo.png'; // Adjust path based on your structure

function Navbar() {
  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">
          <img src={logo} alt="Tours Logo" />
        </Link>
        <ul className="navbar-menu">
          <li><Link to="/">Tours</Link></li>
          <li><Link to="/about">About</Link></li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
