import React from 'react';
import './About.css';

function About() {
  return (
    <div className="about-container">
      <h1 className="h1-white">About Us</h1>
      <p>Welcome to Rio Celeste Tours! We are a dedicated tour company based in the breathtaking Rio Celeste, Costa Rica. Our mission is to provide unforgettable experiences through guided tours of this stunning region.</p>
      
      <h2 className="h2-white">Our Tours</h2>
      <p>We offer a variety of tours that showcase the natural beauty and rich culture of Costa Rica. From hiking adventures to relaxing river cruises, our tours are designed to cater to all interests and activity levels.</p>
      
      <h2 className="h2-white">Contact Us</h2>
      <p>Have questions or want to book a tour? Reach out to us:</p>
      <ul>
        <li><strong className="strong-white">Email:</strong> <a href="mailto:info@riocelestetours.net">info@riocelestetours.net</a></li>
        <li><strong className="strong-white">Phone:</strong> +506 84106388</li>
        <li><strong className="strong-white">Address:</strong> 123 Tour Street, Rio Celeste, Costa Rica</li>
      </ul>

      <h2 className="h2-white">Follow Us</h2>
      <p>Stay connected and follow us on social media for the latest updates and tour highlights:</p>
      <ul>
        <li><a href="https://www.facebook.com/RioCelesteTours" target="_blank" rel="noopener noreferrer">Facebook</a></li>
        <li><a href="https://www.instagram.com/RioCelesteTours" target="_blank" rel="noopener noreferrer">Instagram</a></li>
        <li><a href="https://www.twitter.com/RioCelesteTours" target="_blank" rel="noopener noreferrer">Twitter</a></li>
      </ul>
    </div>
  );
}

export default About;
