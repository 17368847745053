import { collection, addDoc, getDocs, doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "./firebase";

export async function getAllBooks() {
  const booksCollection = collection(db, "book");
  try {
    const querySnapshot = await getDocs(booksCollection);
    const books = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    return books;
  } catch (error) {
    console.error("Error getting books:", error);
    return [];
  }
}

export async function getBookCount() {
  const booksCollection = collection(db, "book");
  try {
    const querySnapshot = await getDocs(booksCollection);
    const booksCount = querySnapshot.docs.length;
    return booksCount;
  } catch (error) {
    console.error("Error getting books count:", error);
    return 0; // Return 0 if there's an error
  }
}

export async function addEmptyBook(bookId) {
  const booksCollection = collection(db, "book");
  try {
    const newBook = {
      id: bookId
    };
    const docRef = await addDoc(booksCollection, newBook);
    console.log("Book added");
    return docRef.id;
  } catch (error) {
    console.error("Error adding book:", error);
    return null; // Return null if there's an error
  }
}

export async function updateBook(bookId, bookInfo) {
  const bookDocRef = doc(db, "book", bookId); // Correctly reference the document
  try {
    await updateDoc(bookDocRef, bookInfo);
    console.log("Book updated successfully");
  } catch (error) {
    console.error("Error updating book:", error);
  }
}

export async function getBook(bookId) {
  const bookDocRef = doc(db, "book", bookId); // Reference the specific book document
  try {
    const docSnapshot = await getDoc(bookDocRef);
    if (docSnapshot.exists()) {
      return { id: docSnapshot.id, ...docSnapshot.data() };
    } else {
      console.error("No such document!");
      return null; // Return null if the document does not exist
    }
  } catch (error) {
    console.error("Error getting book:", error);
    return null; // Return null if there's an error
  }
}
