import { collection, addDoc } from "firebase/firestore";
import { db } from "./firebase";


export async function addPayment(payment) {
    const booksCollection = collection(db, "payment");
    try {
      const docRef = await addDoc(booksCollection, payment);
      console.log("payment added")
      return docRef.id
    } catch (error) {
      console.error("Error adding payment:", error);
      return null; // Return null if there's an error
    }
  }