import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { addPayment } from '../../../firebase/payment';
import Alert from '../../Alert/Alert'; // Import Alert component
import './Payment.css'; // Import CSS for styling
import { updateBook } from '../../../firebase/book';

function Payment({ prevStep, tour, bookInfo }) {
  const { bookId, tourId } = useParams(); // Get the ID from URL parameters
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Function to initialize PayPal buttons
    const initializePayPalButtons = () => {
      if (window.paypal && !document.getElementById('paypal-button-container').hasChildNodes()) {
        window.paypal.Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [{
                amount: {
                  value: bookInfo.totalPrice.toFixed(2),
                },
                description: `Tour: ${tour.name}, Date: ${bookInfo.date}, Total Persons: ${bookInfo.numPersons}`,
              }],
            });
          },
          onApprove: async (data, actions) => {
            try {
              const orderData = await actions.order.capture();
              await addPayment({
                ...orderData,
                tourId,
                bookId
              });
              setAlertType('success');
              setAlertMessage('Payment Successful');
              console.log('Payment Successful'); // Debugging log
              try {
                await updateBook(bookId, {
                  ...bookInfo,
                  payment: true
                });
                navigate(`/receipt/${bookId}`);
              } catch (error) {
                console.error("Error updating book:", error);
              }
            } catch (error) {
              console.error('Payment Approval Error:', error);
              setAlertType('error');
              setAlertMessage('Payment processing failed');
              console.log('Payment processing failed'); // Debugging log
            }
          },
          onError: (err) => {
            console.error('PayPal Error:', err);
            setAlertType('error');
            setAlertMessage('Payment failed');
            console.log('Payment failed'); // Debugging log
          },
        }).render('#paypal-button-container');
      }
    };

    // Call the function to initialize PayPal buttons
    initializePayPalButtons();
  }, [bookInfo, tour, tourId, bookId]); // Added tourId and bookId to the dependency array

  const handleAlertClose = () => {
    setAlertMessage('');
    setAlertType('');
    console.log('Alert closed'); // Debugging log
  };

  return (
    <div className="payment-container">
      <h2>Step 4: Payment</h2>

      <div className="summary-container">
        <h3>Tour Summary</h3>
        <div className="summary-item">
          <strong>Tour Name:</strong> {tour.name}
        </div>
        <div className="summary-item">
          <strong>Date:</strong> {bookInfo.date}
        </div>
        <div className="summary-item">
          <strong>Number of Persons:</strong> {bookInfo.numPersons}
        </div>
        <div className="summary-item">
          <strong>Total Price:</strong> ${bookInfo.totalPrice.toFixed(2)}
        </div>
      </div>

      <div id="paypal-button-container"></div>

      <button onClick={prevStep} className="prev-button">
        Previous
      </button>

      {/* Render the Alert component */}
      <Alert message={alertMessage} onClose={handleAlertClose} type={alertType} />
    </div>
  );
}

export default Payment;
