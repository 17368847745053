import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import TourList from './components/TourList/TourList';
import TourDetail from './components/TourDetail/TourDetail';
import About from './components/About/About';
import './App.css';  // Import your CSS file for additional styling
import Book from './components/Book/Book';
import Receipt from './components/Book/Receipt/Receipt';

function App() {
  return (
    <Router>
      <div className="app-container">
        <Navbar />
        <main className="content">
          <Routes>
            <Route path="/" element={<TourList />} />
            <Route path="/tour/:id" element={<TourDetail />} />
            <Route path="/book/:bookId/tour/:tourId" element={<Book />} />
            <Route path="/receipt/:bookId" element={<Receipt />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

// //******************************************UNCOMMENT FOR TESTING PROD******************************************
// import React, { useEffect } from 'react';
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
// import Navbar from './components/Navbar/Navbar';
// import Footer from './components/Footer/Footer';
// import TourList from './components/TourList/TourList';
// import TourDetail from './components/TourDetail/TourDetail';
// import About from './components/About/About';
// import './App.css';  // Import your CSS file for additional styling
// import Book from './components/Book/Book';
// import Receipt from './components/Book/Receipt/Receipt';

// function App() {
//   const location = useLocation();

//   useEffect(() => {
//     alert('This is TEST WEB APP');
//   }, [location]);

//   return (
//     <div className="app-container">
//       <Navbar />
//       <main className="content">
//         <Routes>
//           <Route path="/" element={<TourList />} />
//           <Route path="/tour/:id" element={<TourDetail />} />
//           <Route path="/book/:bookId/tour/:tourId" element={<Book />} />
//           <Route path="/receipt/:bookId" element={<Receipt />} />
//           <Route path="/about" element={<About />} />
//         </Routes>
//       </main>
//       <Footer />
//     </div>
//   );
// }

// export default function AppWithRouter() {
//   return (
//     <Router>
//       <App />
//     </Router>
//   );
// }
