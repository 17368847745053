import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Receipt.css'; // Import CSS for styling
import { getBook } from '../../../firebase/book';
import { getTourById } from '../../../firebase/tour';

function Receipt() {
  const { bookId } = useParams();
  const [tour, setTour] = useState(null);
  const [bookInfo, setBookInfo] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    async function fetchData() {
      try {
        const fetchedBookInfo = await getBook(bookId);
        console.log(fetchedBookInfo.payment)
        if (!fetchedBookInfo || !fetchedBookInfo.payment) {
          setError("No booking found.");
          return;
        }

        const fetchedTour = await getTourById(fetchedBookInfo.tourId);

        if (!fetchedTour) {
          setError("No tour found for the given booking.");
          return;
        }

        setTour(fetchedTour);
        setBookInfo(fetchedBookInfo);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("An error occurred while fetching the booking details.");
      }
    }

    fetchData();
  }, [bookId]);

  if (error) {
    return (
      <div>
        <h1 className="error-text">{error}</h1>
      </div>
    );
  }

  if (!bookInfo || !tour) {
    return (
      <div className="loading-container">
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className="receipt-container">
      <h1 className="bookin-tile-text">Booking Completed!</h1>
      <div className="receipt-summary">
        <div><h2>Confirmation ID:</h2> {bookId}</div>
        <h2>Tour Details</h2>
        <div><strong>Tour Name:</strong> {tour.name}</div>
        <div><strong>Date:</strong> {bookInfo.date}</div>
        <div><strong>Number of Persons:</strong> {bookInfo.numPersons}</div>
        <div><strong>Total Price:</strong> ${bookInfo.totalPrice}</div>
      </div>
      <div className="receipt-contact-info">
        <h2>Contact Information</h2>
        <div><strong>Name:</strong> {bookInfo.firstName + " " + bookInfo.lastName} </div>
        <div><strong>Email:</strong> {bookInfo.email}</div>
        <div><strong>Phone:</strong> {bookInfo.phoneNumber}</div>
        <div><strong>Country Code:</strong> {bookInfo.countryCode}</div>
      </div>
      <button onClick={() => window.print()} className="print-button">
        Print Receipt
      </button>
    </div>
  );
}

export default Receipt;
